export const redirectFBReferrals = () => {
    const trackedParam = 'fbclid';
    if (typeof window !== 'undefined') {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const trackedParamValue= urlParams.get(trackedParam)

      if(trackedParamValue) {
        window.location.href = `https://orders.na.chilipiper.com/router/facebook-ads?${trackedParam}=${trackedParamValue}&oco_souce=wr`;
      }

      //console.log(trackedParam); // will output CODE_FROM_REDIRECT_ON_SERVERSIDE      
    };
  };
