import { redirectFBReferrals } from './src/utils/functions';

// custom css
// import "typeface-montserrat";
// import "typeface-merriweather";
import "typeface-poppins";

// normalize CSS across browsers
import "./src/css/normalize.css";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

// custom CSS styles
import "./src/css/style.css";
import "./src/styles/grid.css";
import "./src/styles/btn.css";
import "./src/styles/style.css";



export const onClientEntry = () => redirectFBReferrals();